<template>
<div>
  <div class="container-fluid header-sub " style="">
    <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
  </div>
  <div> 

    <div class="container-standar text-center">
      <div class="dk-container">
        <h1 class="d-block font-30 text-white font-weight-6 mb-40">PANDUAN IBADAH REAL TRAVEL</h1>
        <div class="  ff-doa mt-10 mb-10 pad-20 card shadow">
          <div class="row justify-center">
             <div class="col-md-3 d-inline-flex cursor-pointer" v-for="(items, i) in panduan" :key="i" > 
               
                <figure class="snip1253" @click.prevent="linkto(items)">
                    <div class="image">
                    <img src="@/assets/images/vector-doa.png" alt="panduan umroh" />
                    </div>
                    <figcaption class="fig-cation-surat">
                    
                    <h3>{{items.name}}</h3>
                    <p>{{items.description}}</p>
                    </figcaption>
                </figure> 
               
            </div> 
             
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-10 mb-res-10 bg-customise-content pt-10 pb-1">
      <section class="mb-50 pt-15">
        <div class="container">
          <div class="row">
            <div class="col col-md-12 text-center mb-20 tiitle-ex">
              <span class="d-block text-white font-28 font-weight-7 roboto">PAKET UMROH</span>
              <span class="d-block text-white font-20">Paket Umroh Terbaik Untuk Anda</span>
            </div>
            <div class="row">
              <package-umroh></package-umroh>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="container-fluid mb-20">
       <div class="col-md-12 mt-10 mb-10 text-center">
        <span class="d-block text-primary font-28 font-weight-7 roboto">PAKET TOUR</span>
        <span class="d-block text-grey font-20">Pilihan Destinasi Wisata Terbaik</span>
      </div>
      <div class="row">
      <beritaList></beritaList>
      </div>
                
    </div>

  </div>
</div>
</template>

<script>
import PackageUmroh from "@/views/components/package-umroh.vue";
import beritaList from "@/views/components/berita-list";
export default {
  components: {
    PackageUmroh,
    beritaList
  },
  data() {
    return {
      panduan:[],
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getData()
  },
  methods: {
      getData(){  
        this.axios.get('https://admin.realtravel.co.id/view/panduan_menu')
       
        .then( response => { 
              let res = response.data
          this.panduan = res
        
        //  postsa.length = this.dataLength
        }).catch( e =>{
          // console.log(e);
          this.err.push(e)
        });
        // console.log();
    },
    linkto(val){
      this.$router.push('/doa-umroh/'+ val.id)
    }
  },
};
</script>