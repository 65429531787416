<template>
  <div>
    <div class="row margin-auto">
      <div class="col-lg-3 col-6 mb-30" v-for="(items, i) in dataTour" :key="i">
        <div class="card shadow h-100 bg-white width-100 pad-10 d-inline-table pos-relative cursor-pointer" @click="onSumbit(items)">
          <div class="container-maskapai hide-mobile">
            <img v-bind:src="items.airline[0].logo" alt/>
          </div>
          <div class="img-home-umroh">
            <template v-if="items.images == 0">
              <img src="@/assets/images/background/tour-1.jpg" :alt="items.slug">
            </template>
            <template v-else >
                <div>
                <img v-bind:src="chesen(items)" style="width:100%" :alt="items.slug" />
              </div>
            </template>
          </div>
          <div class="content mt-2">
            <span class="d-inline-block font-12 text-primary font-weight-7">{{items.nama}}</span>
            <p class="umroh_hotel">
              <span class="d-inline-block width-100 float-right text-left font-weight-7 text-warning font-12 price-umroh">
                {{getDate(items.date_depart)}} <b class="hide-mobile">- {{items.day}} Hari</b>
              </span>
            </p>
            <p class="umroh_hotel hide-mobile" v-for="(hotel, j) in items.hotel" :key="j">
              {{hotel.name}}
              <span>
                <i v-for="n in parseInt(hotel.star)" :key="n" class="fa fa-star"></i>
              </span>
            </p>
          </div>
        
          <span class="d-inline-block width-100 float-left text-center mt-3 font-weight-7 text-warning font-18 font-mobile-14"
          v-bind:class="{'text-decoration': items.diskon != 0}">
            IDR {{ items.amount | numeralFormat('0,0[.]00') }}</span>
          <span class="d-inline-block width-100 float-right text-center mt-3 font-weight-7 text-warning font-18 font-mobile-14" v-if="items.diskon != 0">
            IDR {{ items.amount - items.diskon | numeralFormat('0,0[.]00') }}</span>
        </div>
      </div>
      <div class="col-md-12 text-center mt-10">
        <router-link to="/paket-umroh">
        <span class="bg-white pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-primary margin-auto w-50 text-center d-block cursor-pointer font-20 border-radius-5">
          Lihat Semua
          <i class="fa ml-2 fa-arrow-right"></i>
        </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
 
<script>
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import * as moment from 'moment' 

export default {
    data() {
      return {
          dataTour:[],
          posts: [],
          numberCount: 4,
          totalCount:null,
          parse:null,
          err: []
      }
    },
    watch: {
        
    },    
	mounted: function() {
		this.getData();
	},
    methods:{
      getData() {
        let param = new FormData();
        param.append("api_key", "2222");
        param.append("format", "");
        param.append("ws", "umroh");
        param.append("keyword", ''); 
        param.append("call", "list_umroh"); 
        this.axios
          .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
          .then(response => {
            let res = response.data;
            this.dataparse = res
            this.parseMethod()
            this.totalCount = this.dataparse.data.length
          })
          .catch(error => {
            error;
          });
      },
      parseMethod(){
        this.dataTour = this.dataparse.data.slice(0, this.numberCount)
      },
      countMethod(){
        this.numberCount = this.numberCount + 4
        this.parseMethod()
        
      },
      chesen(val){
        return val.image
      },
      getDate(val){
        moment.locale('ID'); 
        return moment(val).format('LL')
      },
      
      onSumbit(NewVal){
        localStorage.setItem("response-date-depart", JSON.stringify(NewVal.date_depart));
        this.$router.push('/paket-umroh/detail/'+NewVal.slug+'/'+NewVal.date_depart)
      }
    }
};
</script>